import React from "react"

import { makeStyles } from "makeStyles"

// customers
import BangAndOlufsen from "svg/customers/bang-and-olufsen-logo.svg"
import BMW from "svg/customers/BMW-logo-white-default-53px.svg"
import BP from "svg/customers/bp-logo.svg"
import DHL from "svg/customers/dhl-logo.svg"
import Lufthansa from "svg/customers/lufthansa-4.svg"
import Samsung from "svg/customers/samsung-1.svg"

const useStyles = makeStyles()(() => ({
	logosHeight: {
		height: 30,
		maxWidth: 130,
		// background: "#fff",
		padding: 10,
		borderRadius: 5,

		"@media only screen and (max-width: 960px)": {
			height: 20,
		},
	},
}))

const Customers = ({ hasDarkBackground }: Props) => {
	const { classes } = useStyles()
	const logos = [
		{
			svg: <BangAndOlufsen className={classes.logosHeight} />,
		},
		{
			svg: <BMW className={classes.logosHeight} />,
		},
		{
			svg: <BP className={classes.logosHeight} />,
		},
		{
			svg: <DHL className={classes.logosHeight} />,
		},
		{
			svg: <Lufthansa className={classes.logosHeight} />,
		},
		{
			svg: <Samsung className={classes.logosHeight} />,
		},
	]
	return (
		<div
			style={{
				display: "flex",
				flexFlow: "wrap",
				alignItems: "center",
				justifyContent: "center",
				marginTop: 50,
			}}
		>
			{logos.map((logo, i) => {
				return (
					<div
						key={i}
						style={{
							marginRight: 50,
							marginBottom: 30,
							filter: hasDarkBackground ? "invert(10)" : "invert(0)",
						}}
					>
						{logo.svg}
					</div>
				)
			})}
		</div>
	)
}

interface Props {
	hasDarkBackground: boolean
}

export default Customers
